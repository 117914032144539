
<template>
  
  <StringValueRow 
    class="clickable"
    :class="Strings.isEven(index) ? 'rowEven ' : ' rowOdd '"
    :label="companyName()" 
    :value="identifierValue()" 
    labelWidth="rc-box-col-10" 
    valueWidth="rc-box-col-5"
    stylingValue="rc-text-right"
    v-on:click="showCompanyDetails()" />
  
</template>

<script>

import { mapGetters } from 'vuex'
import StringValueRow from '@/components/row/StringValueRow.vue'
import CompanyStats from '@/domain/model/company/CompanyStats.js';
import StringUtils from '@/utils/StringUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';

export default {
  name: 'portal-shared-row-company-stats-row',
  components: {
    StringValueRow,
  },
  data() {
    return {
      stats: new CompanyStats(this.domain),
      Strings: StringUtils,
    }
  },
  props: {
    companyStatsData: { type: Object, default: null },
    identifier: { type: String, default: "" },
    index: { type: Number, default: -1 },
    isMoney: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters([ 
       'domain',
    ]),
    newStats: function() {
      if (this.domain) {
        return new CompanyStats(this.domain, this.companyStatsData);
      }
      return new CompanyStats();
    },
  },
  mounted: function () {
    this.stats = this.newStats;
  },
  watch: {
    
  },
  methods: {
    companyName: function() {
      var name = this.stats.companyName();
      if (this.index > -1) {
        name = StringUtils.format("{0}. {1}", [this.index, name]);
      }
      return name;
    },
    identifierValue: function() {
      var value = this.stats.get(this.identifier);
      if (this.isMoney && value) {
        return value.toFixed(2);
      }
      if (value) {
        return value.toString();
      }
      return "";
    },
    showCompanyDetails: function() {
      var params = {
          id: this.newStats.company().id(),
        };
      this.domain.session().with(this.newStats.company());
      this.$router.push({
        name: ConstUtils.ROUTES.COMPANY.ADMIN.DETAILS,
        params: params,
      });
    },
  },
}

</script>
