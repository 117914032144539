<template>

    <div 
      v-on:click="$emit('click')"
      :class="valueStyling" cmpt="StringValue">
      {{ value }} 
    </div>

</template>

<script>

import { mapGetters } from 'vuex'
import Catelog from '@/domain/session/CanadianEnglish.js';


export default {
  name: 'string-value',
  props: {
    styling: { type: String, default : null },
    value: { type: String, default: "" },
  },
  data() {
    return {
      catelog: new Catelog(),
      catelogKeys: Catelog.KEYS,
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    valueStyling: function() {
      return "rc-text-value " + this.styling;
    },
  }
}
</script>