
import RcDomainMap from "../../common/RcDomainMap.js";
import AppStats from './AppStats.js';

export default class AppStatsMap extends RcDomainMap {
  
  static MODEL_NAME = AppStats.MODEL_NAME  + "Map";
  
  constructor(domain, data) {
    super(domain, data, AppStatsMap.MODEL_NAME);
  }

  copy() {
    var data = this._copy();
    return new AppStatsMap(this.domain, data);
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getAppStats(key);
      values.push(item);
    }
    return values;
  }
  
  sortByCreatedDateDesc() {
    return this.list().sort(function(a, b) {
        if (a.createdDate() > b.createdDate()) {
          return -1;
        }
        if (a.createdDate() < b.createdDate()) {
          return 1;
        }
        return 0; 
      });
  }
  
  sortByCreatedDate() {
    return this.list().sort(function(a, b) {
        if (a.createdDate() < b.createdDate()) {
          return -1;
        }
        if (a.createdDate() > b.createdDate()) {
          return 1;
        }
        return 0; 
      });
  }
  
  sortByInspectionCountDesc() {
    return this.list().sort(function(a, b) {
      if (a.inspections() > b.inspections()) {
        return -1;
      }
      if (a.inspections() < b.inspections()) {
        return 1;
      }
      return 0;
    });
  }
  
  getAppStats(key) {
    var value = super.get(key);
    return new AppStats(this.domain, value);
  }
  
  sortByInspectionCountDescRaw() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = super.get(key);
      values.push(data);
    }
    
    return values.sort(function(a, b) {
      if (a.inspectionCount > b.inspectionCount) {
        return -1;
      }
      if (a.inspectionCount < b.inspectionCount) {
        return 1;
      }
      return 0;
    });
  }
  
  sortByVehicleCountDescRaw() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = super.get(key);
      values.push(data);
    }
    
    return values.sort(function(a, b) {
      if (a.vehicleCount > b.vehicleCount) {
        return -1;
      }
      if (a.vehicleCount < b.vehicleCount) {
        return 1;
      }
      return 0;
    });
  }
  
  sortByEmployeeCountDescRaw() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = super.get(key);
      values.push(data);
    }
    
    return values.sort(function(a, b) {
      if (a.employeeCount > b.employeeCount) {
        return -1;
      }
      if (a.employeeCount < b.employeeCount) {
        return 1;
      }
      return 0;
    });
  }
  
  sortByTimesheetCountDescRaw() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = super.get(key);
      values.push(data);
    }
    
    return values.sort(function(a, b) {
      if (a.timesheetCount > b.timesheetCount) {
        return -1;
      }
      if (a.timesheetCount < b.timesheetCount) {
        return 1;
      }
      return 0;
    });
  }
  
  sortByCheckInCountDescRaw() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = super.get(key);
      values.push(data);
    }
    
    return values.sort(function(a, b) {
      if (a.checkInCount > b.checkInCount) {
        return -1;
      }
      if (a.checkInCount < b.checkInCount) {
        return 1;
      }
      return 0;
    });
  }
}