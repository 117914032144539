<template>

  <Box cmpt="Loading" :showBorder="showBorder">
    <LoadingRow :imageSize="imageSize">
      <slot />
    </LoadingRow>
  </Box>

</template>

<script>

import { mapGetters } from 'vuex'

import Box from "@/portals/shared/library/box/Box.vue";
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";

export default {
  name: 'portals-shared-library-loading-loading',
  components: {
    Box,
    LoadingRow,
  },
  props: {
    align: { type: String, default: "left" },
    showBorder: { type: Boolean, default: false },
    imageSize: { type: String, default: "medium" },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
  },
  methods: {
    alignment: function() {
      if (this.align == "left") {
        return "rc-text-left";
      }
      if (this.align == "right") {
        return "rc-text-right";
      }
      return "rc-text-centre";
    }
  },
}
</script>