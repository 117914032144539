
import RcObject from "@/domain/common/RcObject.js";
//import Inspection from "@/domain/model/inspection/Inspection.js";
import Company from "@/domain/model/company/Company.js";

export default class CompanyStatsListFilter extends RcObject {
  
  static Fields = {
    PAGINATION: 'pagination',
    COMPANY: Company.MODEL_NAME,
    CREATED_DATE_RANGE: 'createDateRange',
    TYPE: 'frequency',
  }
  
  constructor() {
    super({});
  }
  
  withCompany(companyId) {
    this.put(CompanyStatsListFilter.Fields.COMPANY, companyId);
    return this;
  }
  
  withDateRange2Weeks() {
    this.withDateRange('last2Weeks');
    return this;
  }
  
  withPagination(size, number) {
    this.put(CompanyStatsListFilter.Fields.PAGINATION, { size, number });
    return this;
  }
  
  withDateRange(range) {
    this.put(CompanyStatsListFilter.Fields.CREATED_DATE_RANGE, range);
    return this;
  }
  
  withTypeMonthly() {
    this.put(CompanyStatsListFilter.Fields.TYPE, 'monthly');
    return this;
  }

  withTypeDaily() {
    this.put(CompanyStatsListFilter.Fields.TYPE, 'daily');
    return this;
  }

  done() {
    //
  }
}