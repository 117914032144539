<template>

    <Row cmpt="LoadingRow">
      <Column :width="left" />
      <Column :showBorder="showBorder" :class="classes()" :width="middle">
        <img src="@/assets/2022/ReadyChekAnimatedLoading.gif" :width="imageWidth()"/>
          <slot />
      </Column>
      <Column :width="right" />
    </Row>

</template>

<script>

import { mapGetters } from 'vuex'

import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Catalog from "@/domain/session/Catalog.js";

export default {
  name: 'portals-shared-library-loading-loading-row',
  components: {
    Row,
    Column,
  },
  props: {
    align: { type: String, default: "centre" },
    fontSize: { type: String, default: "medium" },
    fontColour: { type: String, default: "label" },
    showBorder: { type: Boolean, default: false },
    imageSize: { type: String, default: "medium" },

    left:     { type: Number, default: 0 },
    right:    { type: Number, default: 0 },

  },
  data() {
    return {
      Catalog: new Catalog(),
      Keys: Catalog.keys(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    middle: function() {
      return 15 - this.left - this.right;
    },
  },
  methods: {
    classes: function() {
      var value = "";
      
      value += this.alignment() + " ";
      value += this.textSize() + " ";
      value += this.textColour() + " ";
      
      return value;
    },
    alignment: function() {
      return "rc-text-" + this.align;
    },
    textSize: function() {
      return "rc-text-" + this.fontSize;
    },
    textColour: function() {
      return "rc-text-" + this.fontColour;
    },
    imageWidth: function() {
      if (this.imageSize === "small") {
        return "15px";
      }
      return "25px"
    }
  },
}
</script>