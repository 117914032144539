<template>

  <div class="row" cmpt="StringValueRow">
    <Label 
      v-on:click="emitClick()"
      :styling="labelStyling" 
      :label="C.get(label)" /> 

    <Value
      v-on:click="emitClick()"
      :styling="valueStyling"
      :value="CU.unescape(value)" />

  </div> <!-- row -->

</template>

<script>

import { mapGetters } from 'vuex'
import Catalog from '@/domain/session/Catalog.js';
import ContentUtils  from '@/utils/ContentUtils.js';
import StringUtils  from '@/utils/StringUtils.js';

import Label from "./StringLabel.vue";
import Value from "./StringValue.vue";

export default {
  name: 'string-value-row',
  components: {
    Value,
    Label,
  },
  props: {
    stylingLabel: { type: String, default : null },
    stylingValue: { type: String, default : null },
    label: { type: String, default: "" },
    value: { type: String, default: "" },
    labelWidth: { type: String, default: "rc-box-col-2" },
    valueWidth: { type: String, default: "rc-box-col-13" },
  },
  data() {
    return {
      C: new Catalog(),
      CU: ContentUtils,
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    valueStyling: function() {
      return this.valueWidth + " " + StringUtils.toString(this.stylingValue);
    },
    labelStyling: function() {
      return this.labelWidth + " " + StringUtils.toString(this.stylingLabel);
    },
  },
  methods: {
    emitClick: function() {
      this.$emit('click');
    }
  }
}
</script>